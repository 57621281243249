/**
 * 文件系统
 * */
export default [
    {
        path: '/fileexport/index',
        component: resolve => require(['../../components/pages/fileexport/index.vue'], resolve),
        meta: {title: '报表文件'}
    },
    {
        path: '/fileexport/history',
        component: resolve => require(['../../components/pages/fileexport/history.vue'], resolve),
        meta: {title: '历史下载'}
    },
]