/**
 * 大数据中心请求接口
 * */
export default [
    {
        path: '/datacenter/base/userWinningLog',
        component: resolve => require(['../../components/pages/datacenter/base/userWinningLog.vue'], resolve),
        meta: {title: '中奖流水'}
    }, {
        path: '/datacenter/base/userReceiveLog',
        component: resolve => require(['../../components/pages/datacenter/base/userReceiveLog.vue'], resolve),
        meta: {title: '领奖流水'}
    }, {
        path: '/datacenter/overview',
        component: resolve => require(['../../components/pages/datacenter/overview.vue'], resolve),
        meta: {title: '大数据概览'}
    }, {
        path: '/datacenter/base/scanCodeLog',
        component: resolve => require(['../../components/pages/datacenter/base/scanCodeLog.vue'], resolve),
        meta: {title: '二维码被扫流水'}
    }, {
        path: '/datacenter/base/userScanCodeLog',
        component: resolve => require(['../../components/pages/datacenter/base/userScanCodeLog.vue'], resolve),
        meta: {title: '用户扫码流水'}
    }, {
        path: '/datacenter/map/marketing',
        component: resolve => require(['../../components/pages/datacenter/map/marketing.vue'], resolve),
        meta: {title: '营销作战沙盘'}
    }
    // 趋势分析
    , {
        path: '/datacenter/analysis/scanTime',
        component: resolve => require(['../../components/pages/datacenter/analysis/scanTime.vue'], resolve),
        meta: {title: '扫码时段'}
    }, {
        path: '/datacenter/analysis/scanCity',
        component: resolve => require(['../../components/pages/datacenter/analysis/scanCity.vue'], resolve),
        meta: {title: '扫码地域'}
    }
    

    // 大数据中心 渠道宝数据展示
    , {
        path: '/datacenter/base/daogouLog',
        component: resolve => require(['../../components/pages/datacenter/base/qudaobao/daogouLog.vue'], resolve),
        meta: {title: '导购流水'}
    }, {
        path: '/datacenter/base/daogouStat',
        component: resolve => require(['../../components/pages/datacenter/base/qudaobao/daogouStat.vue'], resolve),
        meta: {title: '导购统计'}
    }, {
        path: '/datacenter/base/daogouPeople',
        component: resolve => require(['../../components/pages/datacenter/base/qudaobao/daogouPeople.vue'], resolve),
        meta: {title: '导购员统计'}
    }
]