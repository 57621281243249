/**
 * 登录 
 * */
const mainRouterSetUp = [
    {
        path: '/', redirect: '/datacenter/overview'
    },
    {
        path: '/login',
        component: resolve => require(['../../components/pages/common/login.vue'], resolve)
    },
    {
        path: '*',
        component: resolve => require(['../../components/pages/common/404.vue'], resolve)
    },
]
// 可导出多个
export default mainRouterSetUp