import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/css/icon.css';
import './components/common/js/directives';//拖拽
import "babel-polyfill";
import md5 from 'js-md5';
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)
import myCharts from '../src/components/common/js/echarts'

Vue.use(myCharts)

Vue.config.productionTip = false;
Vue.use(ElementUI, {
    size: 'small'
});
Vue.use(VueClipboard);
// 指令 添加必填class require
Vue.directive('require', {
    bind: function(el, binding) {
        el.className += ' form-require';
    }
});

Vue.prototype.$md5 = md5;

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    const access_token = localStorage.getItem('access_token');
	if (!access_token && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		access_token === 'admin' ? next() : next('/403');
	} else {
		// 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
		if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
			Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
				confirmButtonText: '确定'
			});
		} else {
			next();
		}
	}
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
