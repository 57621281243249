/**
 * 3.0渠道管理
 * */
export default [
    /**
     * 配置
     */
    {
        path: '/qudao/allocation',
        component: resolve => require(['../../components/pages/qudao/allocation'], resolve),
        meta: {title: '渠道配置'}
    },
    /**
     * 防窜
     */
    {
        path: '/qudao/fangcuan/config/fangcuanAgencyList',
        component: resolve => require(['../../components/pages/qudao/fangcuan/config/fangcuanAgencyList.vue'], resolve),
        meta: {title: '渠道人员'}
    },
    {
        path: '/qudao/fangcuan/config/editAgency',
        component: resolve => require(['../../components/pages/qudao/fangcuan/config/editAgency.vue'], resolve),
        meta: {title: '经销商编辑'}
    },
    {
        path: '/qudao/fangcuan/config/channerlMap',
        component: resolve => require(['../../components/pages/qudao/fangcuan/config/channerlMap.vue'], resolve),
        meta: {title: '经销商编辑'}
    },
    {
        path: '/qudao/fangcuan/config/ruleList',
        component: resolve => require(['../../components/pages/qudao/fangcuan/config/ruleList.vue'], resolve),
        meta: {title: '防窜规则'}
    },
    {
        path: '/qudao/fangcuan/config/fangcuanStaff',
        component: resolve => require(['../../components/pages/qudao/fangcuan/config/fangcuanStaff.vue'], resolve),
        meta: {title: '稽核人员'}
    },

    /**
     * 防窜流水
     */
    {
        path: '/qudao/fangcuan/inspect/water',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/water.vue'], resolve),
        meta: {title: '稽查记录'}
    },
    {
        path: '/qudao/fangcuan/inspect/record',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/record.vue'], resolve),
        meta: {title: '窜货记录'}
    },
    {
        path: '/qudao/fangcuan/inspect/statistics',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/statistics.vue'], resolve),
        meta: {title: '窜货统计'}
    },
    {
        path: '/qudao/fangcuan/inspect/unusualRecord',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/unusualRecord.vue'], resolve),
        meta: {title: '稽核异常记录'}
    },
    {
        path: '/qudao/fangcuan/inspect/threeCodeCorrelation',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/threeCodeCorrelation.vue'], resolve),
        meta: {title: '三码关联稽核流水'}
    },{
        path: '/qudao/fangcuan/inspect/agentFleeingRecord',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/agentFleeingRecord.vue'], resolve),
        meta: {title: '经销商窜货统计'}
    },{
        path: '/qudao/fangcuan/inspect/employeeFleeingRecord',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/employeeFleeingRecord.vue'], resolve),
        meta: {title: '员工稽核统计'}
    },{
        path: '/qudao/fangcuan/inspect/employeeFleeingRoute',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/employeeFleeingRoute.vue'], resolve),
        meta: {title: '员工稽核路线'}
    },{
        path: '/qudao/fangcuan/inspect/analysisFleeing',
        component: resolve => require(['../../components/pages/qudao/fangcuan/inspect/analysisFleeing.vue'], resolve),
        meta: {title: 'AI可视化窜货分析'}
    },
    /**
     * 防窜出入库
     */
    {
        path: '/qudao/fangcuan/outbound/outboundOrder',
        component: resolve => require(['../../components/pages/qudao/outbound/outboundOrder.vue'], resolve),
        meta: {title: '出库记录'}
    },
    {
        path: '/qudao/fangcuan/outbound/outboundImport',
        component: resolve => require(['../../components/pages/qudao/outbound/outboundImport.vue'], resolve),
        meta: {title: '出库数据'}
    },
    /**
     * 出库系统
     */
    {
        path: '/qudao/warehouse/device',
        component: resolve => require(['../../components/pages/qudao/warehouse/device.vue'], resolve),
        meta: {title: '出库设备'}
    },
    {
        path: '/qudao/warehouse/batch',
        component: resolve => require(['../../components/pages/qudao/warehouse/batch.vue'], resolve),
        meta: {title: '出库批次'}
    },
    {
        path: '/qudao/warehouse/batchOrder',
        component: resolve => require(['../../components/pages/qudao/warehouse/batchOrder.vue'], resolve),
        meta: {title: '出库单详情'}
    },
    {
        path: '/qudao/warehouse/settings',
        component: resolve => require(['../../components/pages/qudao/warehouse/settings.vue'], resolve),
        meta: {title: '出库设置'}
    },
    {
        path: '/qudao/warehouse/list',
        component: resolve => require(['../../components/pages/qudao/warehouse/list.vue'], resolve),
        meta: {title: '出库仓库'}
    },
    {
        path: '/qudao/warehouse/trace',
        component: resolve => require(['../../components/pages/qudao/warehouse/trace.vue'], resolve),
        meta: {title: '出库溯源'}
    },
    {
        path: '/qudao/warehouse/logs',
        component: resolve => require(['../../components/pages/qudao/warehouse/logs.vue'], resolve),
        meta: {title: '出库记录'}
    },
    {
        path: '/qudao/warehouse/goods',
        component: resolve => require(['../../components/pages/qudao/warehouse/goods.vue'], resolve),
        meta: {title: '渠道商品'}
    },
    {
        path: '/qudao/warehouse/data',
        component: resolve => require(['../../components/pages/qudao/warehouse/data.vue'], resolve),
        meta: {title: '出库数据'}
    },
    {
        path: '/qudao/warehouse/countGoods',
        component: resolve => require(['../../components/pages/qudao/warehouse/countGoods.vue'], resolve),
        meta: {title: '出库商品统计'}
    },
    {
        path: '/qudao/warehouse/countChannel',
        component: resolve => require(['../../components/pages/qudao/warehouse/countChannel.vue'], resolve),
        meta: {title: '出库渠道统计'}
    },
    
]