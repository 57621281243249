import Vue from 'vue';
import Router from 'vue-router';
import mainRouter from './routers/mainRouter';//主要路由 tabber等
import configRouter from './routers/configRouter';//首页路由
Vue.use(Router);

export const constantRouterMap = [
    ...mainRouter,
    ...configRouter,
];

// mode更换history模式
// export default new Router({
//     routes: constantRouterMap
// })
const $router = new Router({
    routes: constantRouterMap
})

$router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if(isChunkLoadFailed){
        // 刷新页面
        location.reload();
    }

});

export default $router


