/**
 * 余额资产
 * */
export default [
    {
        path: '/asset/balance/detail',
        component: resolve => require(['../../../components/pages/asset/balance/detail.vue'], resolve),
        meta: {title: '余额资产概况'}
    },

]