/**
 * SCRM用户中心路由
 * */
export default [
    {
        path: '/scrmuser/base/merchantUserList',
        component: resolve => require(['../../components/pages/scrmuser/base/merchantUserList.vue'], resolve),
        meta: {title: '用户列表'}
    }, {
        path: '/scrmuser/senior/userActiveAnalysis',
        component: resolve => require(['../../components/pages/scrmuser/senior/userActiveAnalysis.vue'], resolve),
        meta: {title: '用户活跃分析'}
    },{
        path: '/scrmuser/senior/userBehaviorAnalysis',
        component: resolve => require(['../../components/pages/scrmuser/senior/userBehaviorAnalysis.vue'], resolve),
        meta: {title: '用户行为分析'}
    },{
        path: '/scrmuser/senior/userEquipmentAnalysis',
        component: resolve => require(['../../components/pages/scrmuser/senior/userEquipmentAnalysis.vue'], resolve),
        meta: {title: '用户设备分析'}
    },{
        path: '/scrmuser/senior/userAll',
        component: resolve => require(['../../components/pages/scrmuser/senior/userAll.vue'], resolve),
        meta: {title: '新老用户分析'}
    }
]