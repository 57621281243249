/**
 * 二维码系统
 * */
 export default [
    {
        path: '/qrcode/codeBit',
        component: resolve => require(['../../components/pages/qrcode/codeBit.vue'], resolve),
        meta: {title: '编码位'}
    },
    {
        path: '/qrcode/codeBitQuery',
        component: resolve => require(['../../components/pages/qrcode/codeBitQuery.vue'], resolve),
        meta: {title: '编码位参数'}
    },
    {
        path: '/qrcode/ccodeTemplate',
        component: resolve => require(['../../components/pages/qrcode/ccodeTemplate.vue'], resolve),
        meta: {title: '生码模板'}
    },
    {
        path: '/qrcode/ccodeTemplateInfo',
        component: resolve => require(['../../components/pages/qrcode/ccodeTemplateInfo.vue'], resolve),
        meta: {title: '生码模板详情'}
    },
    {
        path: '/qrcode/codeOrder',
        component: resolve => require(['../../components/pages/qrcode/order.vue'], resolve),
        meta: {title: '二维码订单'}
    },
    {
        path: '/qrcode/orderCreate',
        component: resolve => require(['../../components/pages/qrcode/orderCreate.vue'], resolve),
        meta: {title: '生成二维码'}
    },
    {
        path: '/qrcode/codeAssociation',
        component: resolve => require(['../../components/pages/qrcode/codeAssociation.vue'], resolve),
        meta: {title: '多码后关联'}
    },
    {
        path: '/qrcode/codeAssociationInfo',
        component: resolve => require(['../../components/pages/qrcode/codeAssociationInfo.vue'], resolve),
        meta: {title: '查看二维码'}
    },
    {
        path: '/qrcode/codeAssociationErr',
        component: resolve => require(['../../components/pages/qrcode/codeAssociationErr.vue'], resolve),
        meta: {title: '异常数据'}
    },
    {
        path: '/qrcode/orderInfo',
        component: resolve => require(['../../components/pages/qrcode/orderInfo.vue'], resolve),
        meta: {title: '订单详情'}
    },
    {
        path: '/qrcode/codeQuery',
        component: resolve => require(['../../components/pages/qrcode/codeQuery.vue'], resolve),
        meta: {title: '二维码查询'}
    }
]