/**
 * 商家信息
 * */
export default [
    {
        path: '/user/info',
        component: resolve => require(['../../components/pages/user/info.vue'], resolve),
        meta: {title: '商家详细'}
    },
    {
        path: '/user/testEcharts',
        component: resolve => require(['../../components/pages/user/testEcharts.vue'], resolve),
        meta: {title: '测试'}
    },
    {
        path: '/user/openConfig',
        component: resolve => require(['../../components/pages/user/openConfig.vue'], resolve),
        meta: {title: '开放接口密钥管理'}
    },
]