/**
 * 渠道宝系统
 * */
export default [
    /* *核销宝 */
    {
        path: '/qudaobao/channels_list',
        component: resolve => require(['../../components/pages/qudaobao/channels_list'], resolve),
        meta: {
            title: '渠道列表'
        }
    },
    {
        path: '/qudaobao/grade_list',
        component: resolve => require(['../../components/pages/qudaobao/grade_list'], resolve),
        meta: {
            title: '渠道层级'
        }
    },
    {
        path: '/qudaobao/department_list',
        component: resolve => require(['../../components/pages/qudaobao/department_list'], resolve),
        meta: {
            title: '部门管理'
        }
    },
    {
        path: '/qudaobao/goods_list',
        component: resolve => require(['../../components/pages/qudaobao/goods_list'], resolve),
        meta: {
            title: '商品管理'
        }
    },

    {
        path: '/qudaobao/prize_list',
        component: resolve => require(['../../components/pages/qudaobao/prize_list'], resolve),
        meta: {
            title: '奖品管理'
        }
    },

    {
        path: '/qudaobao/store_staff_list',
        component: resolve => require(['../../components/pages/qudaobao/store_staff_list'], resolve),
        meta: {
            title: '终端门店人员'
        }
    },
    {
        path: '/qudaobao/agency_staff_list',
        component: resolve => require(['../../components/pages/qudaobao/agency_staff_list'], resolve),
        meta: {
            title: '经销商人员'
        }
    },
    {
        path: '/qudaobao/factory_staff_list',
        component: resolve => require(['../../components/pages/qudaobao/factory_staff_list'], resolve),
        meta: {
            title: '商户人员'
        }
    },

    {
        path: '/qudaobao/task_list',
        component: resolve => require(['../../components/pages/qudaobao/task_list'], resolve),
        meta: {
            title: '任务管理'
        }
    },
    {
        path: '/qudaobao/agencies_list',
        component: resolve => require(['../../components/pages/qudaobao/agencies_list'], resolve),
        meta: {
            title: '经销商列表'
        }
    },
    {
        path: '/qudaobao/store_list',
        component: resolve => require(['../../components/pages/qudaobao/store_list'], resolve),
        meta: {
            title: '门店列表'
        }
    },
    {
        path: '/qudaobao/channel_agency_goods',
        component: resolve => require(['../../components/pages/qudaobao/channel_agency_goods'],
            resolve),
        meta: {
            title: '下级补货数据'
        }
    },
    {
        path: '/qudaobao/channel_good_agencies',
        component: resolve => require(['../../components/pages/qudaobao/channel_good_agencies'],
            resolve),
        meta: {
            title: '商品补货数据'
        }
    },
    {
        path: '/qudaobao/staff_task_agencies',
        component: resolve => require(['../../components/pages/qudaobao/staff_task_agencies'],
            resolve),
        meta: {
            title: '子任务管理'
        }
    },

    {
        path: '/qudaobao/staff_task_examine_logs',
        component: resolve => require(['../../components/pages/qudaobao/staff_task_examine_logs'],
            resolve),
        meta: {
            title: '任务审核记录'
        }
    },


    {
        path: '/qudaobao/task_good_logs',
        component: resolve => require(['../../components/pages/qudaobao/task_good_logs'], resolve),
        meta: {
            title: '奖励记录'
        }
    },
    {
        path: '/qudaobao/good_restock',
        component: resolve => require(['../../components/pages/qudaobao/good_restock_logs'],
            resolve),
        meta: {
            title: '核销补货记录'
        }
    },
    {
        path: '/qudaobao/good_restock_report',
        component: resolve => require(['../../components/pages/qudaobao/good_restock_report'],
            resolve),
        meta: {
            title: '核销补货报表'
        }
    },
    {
        path: '/qudaobao/good_restock_daily_report',
        component: resolve => require([
            '../../components/pages/qudaobao/good_restock_daily_report'
        ], resolve),
        meta: {
            title: '核销补货日报表'
        }
    },
    {
        path: '/qudaobao/good_water',
        component: resolve => require([
            '../../components/pages/qudaobao/good_water'
        ], resolve),
        meta: {
            title: '用户核销流水'
        }
    },
    {
        path: '/qudaobao/role/role_list',
        component: resolve => require([
            '../../components/pages/qudaobao/role/role_list'
        ], resolve),
        meta: {
            title: '渠道角色'
        }
    },
    {
        path: '/qudaobao/personnel/guide_list',
        component: resolve => require([
            '../../components/pages/qudaobao/personnel/guide_list'
        ], resolve),
        meta: {
            title: '导购人员'
        }
    },
    {
        path: '/qudaobao/ranking/activityList',
        component: resolve => require([
            '../../components/pages/qudaobao/ranking/activityList.vue'
        ], resolve),
        meta: {
            title: '排行榜活动'
        }
    },
    {
        path: '/qudaobao/ranking/winningList',
        component: resolve => require([
            '../../components/pages/qudaobao/ranking/winningList.vue'
        ], resolve),
        meta: {
            title: '排行榜榜单排名'
        }
    },
    /** 
     * 导购活动
     * */ 
    {
        path: '/qudaobao/shopping/activityList',
        component: resolve => require(['../../components/pages/qudaobao/shopping/activityList.vue'], resolve),
        meta: {title: '活动列表'}
    },
    {
        path: '/qudaobao/shopping/activityCreate',
        component: resolve => require(['../../components/pages/qudaobao/shopping/activityCreate.vue'], resolve),
        meta: {title: '导购活动创建'}
    },
    {
        path: '/qudaobao/shopping/bindLog',
        component: resolve => require(['../../components/pages/qudaobao/shopping/bindLog.vue'], resolve),
        meta: {title: '导购绑码记录'}
    },
    {
        path: '/qudaobao/shopping/errorBindLog',
        component: resolve => require(['../../components/pages/qudaobao/shopping/errorBindLog.vue'], resolve),
        meta: {title: '异常绑码记录'}
    },
    /** 
     * 邀请活动
     * */ 
    {
        path: '/qudaobao/invite/activityList',
        component: resolve => require(['../../components/pages/qudaobao/invite/activityList.vue'], resolve),
        meta: {title: '邀请项目'}
    },
    {
        path: '/qudaobao/invite/activityCreate',
        component: resolve => require(['../../components/pages/qudaobao/invite/activityCreate.vue'], resolve),
        meta: {title: '邀请活动'}
    },
    {
        path: '/qudaobao/invite/recordList',
        component: resolve => require(['../../components/pages/qudaobao/invite/recordList.vue'], resolve),
        meta: {title: '邀请记录'}
    },


    /**
     * 提现管理
     * */
    {
        path: '/qudaobao/withdrawal/config',
        component: resolve => require(['../../components/pages/qudaobao/withdrawal/config.vue'], resolve),
        meta: {title: '配置管理'}
    },
    {
        path: '/qudaobao/withdrawal/list',
        component: resolve => require(['../../components/pages/qudaobao/withdrawal/list.vue'], resolve),
        meta: {title: '提现列表'}
    },
    {
        path: '/qudaobao/ranking/activityCycle',
        component: resolve => require(['../../components/pages/qudaobao/ranking/activityCycle.vue'], resolve),
        meta: {title: '排行榜榜单'}
    },


    /**
     * 防窜
     */
    {
        path: '/qudaobao/fangcuan/config/fangcuanAgencyList',
        component: resolve => require(['../../components/pages/qudaobao/fangcuan/config/fangcuanAgencyList.vue'], resolve),
        meta: {title: '经销商列表'}
    },
    {
        path: '/qudaobao/fangcuan/config/editAgency',
        component: resolve => require(['../../components/pages/qudaobao/fangcuan/config/editAgency.vue'], resolve),
        meta: {title: '经销商编辑'}
    },
    {
        path: '/qudaobao/fangcuan/config/channerlMap',
        component: resolve => require(['../../components/pages/qudaobao/fangcuan/config/channerlMap.vue'], resolve),
        meta: {title: '经销商编辑'}
    },

    /**
     * 导购活动定投
     */

    {
        path: '/qudaobao/shopping/advertisingList',
        component: resolve => require(['../../components/pages/qudaobao/shopping/advertisingList.vue'], resolve),
        meta: {title: '定投列表'}
    },

]